/* .dash-Tile-wrap {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 2fr));

  grid-gap: 16px;
}

.dash-Tile-wrap > * {
  min-height: 200px;
}

@media only screen and (min-width: 632px) {
  .dash-Tile-lg {
    grid-column: 1 / 3;
  }
} */
