/* THEMES------------------------------------------------ */

:root {
  /* fonts */
  --selected-font-color: #34a8fe;
  --primary-font-color: #2f3d4d;
  --secondary-font-color: rgba(47, 61, 77, 0.6);
  --disabled-font-color: rgba(47, 61, 77, 0.3);

  /* background */
  --background-color: #ffffff;
  --foreground-color: #ffffff;
  --hover-color: rgba(236, 239, 241, 0.6);

  /* borders */
  --border: 1px solid #eceff1;

  /* lines */
  --line-color: #eceff1;

  /* shadows */
  --foreground-shadow: 0px 4px 12px rgba(17, 51, 83, 0.02);
  --modal-shadow: 0px 20px 35px rgba(33, 56, 69, 0.2),
    0px 4px 12px rgba(17, 51, 83, 0.02);
}

[data-theme="dark"] {
  /* fonts */
  --selected-font-color: #34a8fe;
  --primary-font-color: #ffffff;
  --secondary-font-color: rgba(255, 255, 255, 0.6);
  --disabled-font-color: rgba(255, 255, 255, 0.3);

  /* background */
  --background-color: #1c252e;
  --foreground-color: #28323c;
  --hover-color: rgba(255, 255, 255, 0.1);

  /* borders */
  --border: none;

  /* lines */
  --line-color: rgba(255, 255, 255, 0.1);

  /* shadows */
  /* --foreground-shadow: none; */
  --foreground-shadow: 0px 20px 40px rgba(0, 0, 0, 0.04);
  /* --foreground-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1),
    0px 4px 4px rgba(0, 0, 0, 0.05); */

  --modal-shadow: 0px 20px 35px rgba(0, 0, 0, 0.4);
}
/* DEFAULT STYLING------------------------------------------------ */
a {
  text-decoration: none; /* no underline */
}

/* LAYOUT------------------------------------------------ */
html,
body {
  margin: 0;
  position: relative;

  box-sizing: border-box;

  background-color: var(--background-color);
  color: var(--primary-font-color);

  font-family: "Inter", -apple-system, "Helvetica Neue", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", BlinkMacSystemFont;
  font-weight: 400;
  font-size: 1rem;
}

#root {
  min-height: 100vh;
  padding: 8px;
  box-sizing: border-box;
  overflow: hidden;
  display: grid;
  grid-template-columns: 1fr;
}

/* GLOABLS------------------------------------------------ */
.flex-align-cent {
  display: flex;
  align-items: center;
}
.flex-align-bot {
  display: flex;
  align-items: flex-end;
}
.flex-just-out {
  display: flex;
  justify-content: space-between;
}
.flex-just-cent {
  display: flex;
  justify-content: center;
}
.flex-just-lft {
  display: flex;
  justify-content: flex-start;
}
.flex-just-rit {
  display: flex;
  justify-content: flex-end;
}
.flex-col {
  display: flex;
  flex-direction: column;
}

.mobile-only {
  display: flex;
}
.desktop-only {
  display: none;
}

/* GLOBAL COMPS------------------------------------------------ */
.foreground-wrap {
  background: var(--foreground-color);
  border: var(--border);
  box-shadow: var(--foreground-shadow);
  box-sizing: border-box;
  border-radius: 10px;
  /* overflow: hidden; */
}
.foreground-wrap-outline {
  /* background: var(--foreground-color); */
  border: 1px solid var(--line-color);
  box-shadow: var(--foreground-shadow);
  box-sizing: border-box;
  border-radius: 10px;
  /* overflow: hidden; */
}

.global-app-wrap {
  padding: 8px;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
}

.modal {
  box-shadow: var(--modal-shadow);
  border-radius: 5px;
}

button {
  outline: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 16px;
  border-radius: 5px;
  height: 36px;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  box-sizing: border-box;
  cursor: pointer;
  border: none;
  transition: 0.25s cubic-bezier(0.25, 1, 0.5, 1);
}
button > * {
  box-sizing: border-box;
}
button svg {
  width: 20px;
  height: 20px;
  margin-right: 8px;
  transition: 0.25s cubic-bezier(0.25, 1, 0.5, 1);
}

.button-fill {
  background: #34a8fe;
  color: #fff;
}
.button-fill svg path {
  fill: #fff;
}

.button-line {
  border: 1px solid #34a8fe;
  background: none;
}

.button-txt {
  color: #34a8fe;
  background: none;
}
.button-line svg path,
.button-txt svg path {
  fill: #34a8fe;
}

.button-line:hover,
.button-txt:hover {
  background: rgba(52, 168, 254, 0.3);
}

.button-line-theme {
  border: 1px solid var(--primary-font-color);
  color: var(--primary-font-color);
  background: none;
}
.button-txt-theme {
  background: none;
  color: var(--primary-font-color);
}
.button-txt-theme-lite {
  background: none;
  color: var(--secondary-font-color);
}

.button-line-theme svg path,
.button-txt-theme svg path {
  fill: var(--primary-font-color);
}
.button-txt-theme-lite:hover,
.button-line-theme:hover,
.button-txt-them:hover {
  background: var(--hover-color);
  color: var(--primary-font-color);
}

.button-nav {
  background: none;
  color: var(--primary-font-color);
  fill: var(--primary-font-color);
  opacity: 0.6;
}

.button-nav:hover,
.button-nav:hover svg path {
  opacity: 1;
}

.button-icon,
.button-icon-button,
.button-icon-back {
  padding: 6px;
  background: none;
}
.button-icon svg,
.button-icon-button svg,
.button-icon-back svg {
  width: 24px;
  height: 24px;
  margin: 0;
}
.button-icon svg path {
  fill: var(--disabled-font-color);
}

.button-icon:hover svg path {
  fill: var(--primary-font-color);
}
.active-icon svg path {
  fill: var(--selected-font-color);
}

.button-icon-back svg path {
  fill: var(--disabled-font-color);
}
.button-icon-back:hover,
.button-icon-back:hover svg path {
  background: var(--hover-color);
  fill: var(--primary-font-color);
}

.button-icon-back-selected svg path {
  fill: var(--primary-font-color);
}

.button-icon-button svg path {
  fill: var(--primary-font-color);
}
.button-icon-button:hover {
  background: var(--hover-color);
}

/* FONTS------------------------------------------------ */
h1,
.title {
}

p,
.txt {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
}

.sub-txt {
  font-family: Inter;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 20px;
}

.bold {
  font-weight: 500;
}

.thin {
  font-weight: 300;
}

.selected-txt {
  color: var(--selected-font-color);
}
.primary-txt {
  color: var(--primary-font-color);
}
.secondary-txt {
  color: var(--secondary-font-color);
}
.disabled-txt {
  color: var(--disabled-font-color);
}

/* ANIMTIONS------------------------------------------------ */
.rev-fade,
.rev-up,
.rev-down,
.rev-rit,
.rev-lft {
  opacity: 0;
  animation: rev 0.45s cubic-bezier(0.25, 1, 0.5, 1) forwards;
}
.rev-up {
  transform: translateY(10%);
}
.rev-down {
  transform: translateY(-10%);
}
.rev-rit {
  transform: translateX(-10%);
}
.rev-lft {
  transform: translateX(10%);
}

.global-trans {
  transition: 0.25s cubic-bezier(0.25, 1, 0.5, 1);
}

@keyframes rev {
  0% {
    opacity: 0;
  }
  100% {
    transform: translateY(0) translateX(0);
    opacity: 1;
  }
}

/* MICILANIOUS------------------------------------------------ */

.line-vert {
  border-left: 1px solid var(--disabled-font-color);
}
.line-horz {
  border-bottom: 1px solid var(--disabled-font-color);
}

/* ERORMSG------------------------------------------------------- */
.console-app-404-msg {
  height: 100%;
  width: 100%;
  font-size: 32px;
  font-weight: 600;
  color: var(--disabled-font-color);
  text-align: center;
  margin: 32px auto;
}

/* RESPNSIVNESS------------------------------------------------ */
@media only screen and (min-width: 768px) {
  #root {
    padding: 16px;
  }
}
@media only screen and (min-width: 1048px) {
  #root {
    grid-template-columns: auto 1fr;
    grid-column-gap: 24px;
  }
  .mobile-only {
    display: none;
  }
  .desktop-only {
    display: flex;
  }
}
