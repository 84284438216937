@import url(https://rsms.me/inter/inter.css);
/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

html {
  font-family: "Inter", sans-serif;
}
@supports (font-variation-settings: normal) {
  html {
    font-family: "Inter var", sans-serif;
  }
}
h1 {
}
h2 {
  font-size: 1.75;
  /* font-size: 2.25rem; */
  font-weight: 500;
  line-height: 1.3;
  margin: 0;
  /* margin: 0 0 1em; */
}
h3 {
  font-size: 1.3125rem;
  font-weight: 500;
  line-height: 1.3;
  margin: 0;
  /* margin: 0 0 1em; */
}
h4 {
  font-size: 1.09375rem;
  font-weight: 500;
  line-height: 1.3;
  margin: 0;
  /* margin: 0 0 1em; */
}
h5 {
  font-size: 1.75;
  font-weight: 500;
  line-height: 1.3;
  margin: 0;
}
p {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.71429;
  margin: 0 0 1em;
}

/* THEMES------------------------------------------------ */

:root {
  /* fonts */
  --selected-font-color: #34a8fe;
  --primary-font-color: #2f3d4d;
  --secondary-font-color: rgba(47, 61, 77, 0.6);
  --disabled-font-color: rgba(47, 61, 77, 0.3);

  /* background */
  --background-color: #ffffff;
  --foreground-color: #ffffff;
  --hover-color: rgba(236, 239, 241, 0.6);

  /* borders */
  --border: 1px solid #eceff1;

  /* lines */
  --line-color: #eceff1;

  /* shadows */
  --foreground-shadow: 0px 4px 12px rgba(17, 51, 83, 0.02);
  --modal-shadow: 0px 20px 35px rgba(33, 56, 69, 0.2),
    0px 4px 12px rgba(17, 51, 83, 0.02);
}

[data-theme="dark"] {
  /* fonts */
  --selected-font-color: #34a8fe;
  --primary-font-color: #ffffff;
  --secondary-font-color: rgba(255, 255, 255, 0.6);
  --disabled-font-color: rgba(255, 255, 255, 0.3);

  /* background */
  --background-color: #1c252e;
  --foreground-color: #28323c;
  --hover-color: rgba(255, 255, 255, 0.1);

  /* borders */
  --border: none;

  /* lines */
  --line-color: rgba(255, 255, 255, 0.1);

  /* shadows */
  /* --foreground-shadow: none; */
  --foreground-shadow: 0px 20px 40px rgba(0, 0, 0, 0.04);
  /* --foreground-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1),
    0px 4px 4px rgba(0, 0, 0, 0.05); */

  --modal-shadow: 0px 20px 35px rgba(0, 0, 0, 0.4);
}
/* DEFAULT STYLING------------------------------------------------ */
a {
  text-decoration: none; /* no underline */
}

/* LAYOUT------------------------------------------------ */
html,
body {
  margin: 0;
  position: relative;

  box-sizing: border-box;

  background-color: #ffffff;

  background-color: var(--background-color);
  color: #2f3d4d;
  color: var(--primary-font-color);

  font-family: "Inter", -apple-system, "Helvetica Neue", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", BlinkMacSystemFont;
  font-weight: 400;
  font-size: 1rem;
}

#root {
  min-height: 100vh;
  padding: 8px;
  box-sizing: border-box;
  overflow: hidden;
  display: grid;
  grid-template-columns: 1fr;
}

/* GLOABLS------------------------------------------------ */
.flex-align-cent {
  display: flex;
  align-items: center;
}
.flex-align-bot {
  display: flex;
  align-items: flex-end;
}
.flex-just-out {
  display: flex;
  justify-content: space-between;
}
.flex-just-cent {
  display: flex;
  justify-content: center;
}
.flex-just-lft {
  display: flex;
  justify-content: flex-start;
}
.flex-just-rit {
  display: flex;
  justify-content: flex-end;
}
.flex-col {
  display: flex;
  flex-direction: column;
}

.mobile-only {
  display: flex;
}
.desktop-only {
  display: none;
}

/* GLOBAL COMPS------------------------------------------------ */
.foreground-wrap {
  background: #ffffff;
  background: var(--foreground-color);
  border: 1px solid #eceff1;
  border: var(--border);
  box-shadow: 0px 4px 12px rgba(17, 51, 83, 0.02);
  box-shadow: var(--foreground-shadow);
  box-sizing: border-box;
  border-radius: 10px;
  /* overflow: hidden; */
}
.foreground-wrap-outline {
  /* background: var(--foreground-color); */
  border: 1px solid #eceff1;
  border: 1px solid var(--line-color);
  box-shadow: 0px 4px 12px rgba(17, 51, 83, 0.02);
  box-shadow: var(--foreground-shadow);
  box-sizing: border-box;
  border-radius: 10px;
  /* overflow: hidden; */
}

.global-app-wrap {
  padding: 8px;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
}

.modal {
  box-shadow: 0px 20px 35px rgba(33, 56, 69, 0.2),
    0px 4px 12px rgba(17, 51, 83, 0.02);
  box-shadow: var(--modal-shadow);
  border-radius: 5px;
}

button {
  outline: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 16px;
  border-radius: 5px;
  height: 36px;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  box-sizing: border-box;
  cursor: pointer;
  border: none;
  transition: 0.25s cubic-bezier(0.25, 1, 0.5, 1);
}
button > * {
  box-sizing: border-box;
}
button svg {
  width: 20px;
  height: 20px;
  margin-right: 8px;
  transition: 0.25s cubic-bezier(0.25, 1, 0.5, 1);
}

.button-fill {
  background: #34a8fe;
  color: #fff;
}
.button-fill svg path {
  fill: #fff;
}

.button-line {
  border: 1px solid #34a8fe;
  background: none;
}

.button-txt {
  color: #34a8fe;
  background: none;
}
.button-line svg path,
.button-txt svg path {
  fill: #34a8fe;
}

.button-line:hover,
.button-txt:hover {
  background: rgba(52, 168, 254, 0.3);
}

.button-line-theme {
  border: 1px solid #2f3d4d;
  border: 1px solid var(--primary-font-color);
  color: #2f3d4d;
  color: var(--primary-font-color);
  background: none;
}
.button-txt-theme {
  background: none;
  color: #2f3d4d;
  color: var(--primary-font-color);
}
.button-txt-theme-lite {
  background: none;
  color: rgba(47, 61, 77, 0.6);
  color: var(--secondary-font-color);
}

.button-line-theme svg path,
.button-txt-theme svg path {
  fill: #2f3d4d;
  fill: var(--primary-font-color);
}
.button-txt-theme-lite:hover,
.button-line-theme:hover,
.button-txt-them:hover {
  background: rgba(236, 239, 241, 0.6);
  background: var(--hover-color);
  color: #2f3d4d;
  color: var(--primary-font-color);
}

.button-nav {
  background: none;
  color: #2f3d4d;
  color: var(--primary-font-color);
  fill: #2f3d4d;
  fill: var(--primary-font-color);
  opacity: 0.6;
}

.button-nav:hover,
.button-nav:hover svg path {
  opacity: 1;
}

.button-icon,
.button-icon-button,
.button-icon-back {
  padding: 6px;
  background: none;
}
.button-icon svg,
.button-icon-button svg,
.button-icon-back svg {
  width: 24px;
  height: 24px;
  margin: 0;
}
.button-icon svg path {
  fill: rgba(47, 61, 77, 0.3);
  fill: var(--disabled-font-color);
}

.button-icon:hover svg path {
  fill: #2f3d4d;
  fill: var(--primary-font-color);
}
.active-icon svg path {
  fill: #34a8fe;
  fill: var(--selected-font-color);
}

.button-icon-back svg path {
  fill: rgba(47, 61, 77, 0.3);
  fill: var(--disabled-font-color);
}
.button-icon-back:hover,
.button-icon-back:hover svg path {
  background: rgba(236, 239, 241, 0.6);
  background: var(--hover-color);
  fill: #2f3d4d;
  fill: var(--primary-font-color);
}

.button-icon-back-selected svg path {
  fill: #2f3d4d;
  fill: var(--primary-font-color);
}

.button-icon-button svg path {
  fill: #2f3d4d;
  fill: var(--primary-font-color);
}
.button-icon-button:hover {
  background: rgba(236, 239, 241, 0.6);
  background: var(--hover-color);
}

/* FONTS------------------------------------------------ */
h1,
.title {
}

p,
.txt {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
}

.sub-txt {
  font-family: Inter;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 20px;
}

.bold {
  font-weight: 500;
}

.thin {
  font-weight: 300;
}

.selected-txt {
  color: #34a8fe;
  color: var(--selected-font-color);
}
.primary-txt {
  color: #2f3d4d;
  color: var(--primary-font-color);
}
.secondary-txt {
  color: rgba(47, 61, 77, 0.6);
  color: var(--secondary-font-color);
}
.disabled-txt {
  color: rgba(47, 61, 77, 0.3);
  color: var(--disabled-font-color);
}

/* ANIMTIONS------------------------------------------------ */
.rev-fade,
.rev-up,
.rev-down,
.rev-rit,
.rev-lft {
  opacity: 0;
  -webkit-animation: rev 0.45s cubic-bezier(0.25, 1, 0.5, 1) forwards;
          animation: rev 0.45s cubic-bezier(0.25, 1, 0.5, 1) forwards;
}
.rev-up {
  transform: translateY(10%);
}
.rev-down {
  transform: translateY(-10%);
}
.rev-rit {
  transform: translateX(-10%);
}
.rev-lft {
  transform: translateX(10%);
}

.global-trans {
  transition: 0.25s cubic-bezier(0.25, 1, 0.5, 1);
}

@-webkit-keyframes rev {
  0% {
    opacity: 0;
  }
  100% {
    transform: translateY(0) translateX(0);
    opacity: 1;
  }
}

@keyframes rev {
  0% {
    opacity: 0;
  }
  100% {
    transform: translateY(0) translateX(0);
    opacity: 1;
  }
}

/* MICILANIOUS------------------------------------------------ */

.line-vert {
  border-left: 1px solid rgba(47, 61, 77, 0.3);
  border-left: 1px solid var(--disabled-font-color);
}
.line-horz {
  border-bottom: 1px solid rgba(47, 61, 77, 0.3);
  border-bottom: 1px solid var(--disabled-font-color);
}

/* ERORMSG------------------------------------------------------- */
.console-app-404-msg {
  height: 100%;
  width: 100%;
  font-size: 32px;
  font-weight: 600;
  color: rgba(47, 61, 77, 0.3);
  color: var(--disabled-font-color);
  text-align: center;
  margin: 32px auto;
}

/* RESPNSIVNESS------------------------------------------------ */
@media only screen and (min-width: 768px) {
  #root {
    padding: 16px;
  }
}
@media only screen and (min-width: 1048px) {
  #root {
    grid-template-columns: auto 1fr;
    grid-column-gap: 24px;
  }
  .mobile-only {
    display: none;
  }
  .desktop-only {
    display: flex;
  }
}

.header-wrap {
  position: relative;
  height: 48px;
}

.mobile-menu-button {
  margin-right: 16px;
}

.header-profile-wrap {
  position: relative;
  height: 100%;
}

.header-profile-GTF-button {
  margin-right: 2px;
  box-sizing: border-box;
}

.header-profile-img {
  height: 32px;
  width: 32px;
  border-radius: 100%;
  overflow: hidden;
  border: 4px solid rgba(0, 0, 0, 0);
  margin: -2px -2px -2px 16px;
  cursor: pointer;
}

.header-profile-img img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.header-profile-img:hover {
  border: 4px solid var(--disabled-font-color);
}
@media only screen and (max-width: 370px) {
  .header-profile-GTF-button {
    display: none;
  }
}

.header-profile-menu-wrap {
  position: absolute;
  top: 100%;
  right: 0;
  background: var(--foreground-color);
  box-sizing: border-box;
  box-shadow: var(--modal-shadow);
  border-radius: 10px;
  width: 285px;
  z-index: 51;
  /* width: 256px; */
}

.header-profile-menu-wrap section {
  width: 100%;
  border-bottom: 1px solid var(--line-color);
  padding: 16px 0;
}

.header-profile-menu-pic-sec-wrap {
  position: relative;
  margin-bottom: 12px;
}

.header-profile-menu-button {
  border-radius: 20px;
  border: 1px solid var(--line-color);
  height: 28px;
}

.header-profile-menu-pic-wrap {
  height: 64px;
  width: 64px;
  border-radius: 100%;
  overflow: hidden;
  cursor: pointer;
}

.header-profile-menu-badge-wrap {
  position: absolute;
  bottom: -8px;
  right: -8px;
  height: 32px;
  width: 32px;
  border-radius: 100%;
  box-sizing: border-box;
  cursor: pointer;
  background: var(--foreground-color);
}

.header-profile-menu-badge {
  height: 24px;
  width: 24px;
  border-radius: 100%;
  background: #ffffff;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
}

.header-profile-menu-badge svg {
  height: 18px;
  width: 18px;
}
.header-profile-menu-badge svg path {
  fill: #bdbdbd;
}

.header-profile-menu-item {
  cursor: pointer;
  width: 100%;
  padding: 0 60px;
  box-sizing: border-box;
  height: 32px;
}

.header-profile-menu-item > * {
  height: 24px;
}

.header-profile-menu-item svg {
  height: 24px;
  width: 24px;
}
.header-profile-menu-item svg path {
  fill: var(--disabled-font-color);
}
.header-profile-menu-item:hover {
  background: var(--hover-color);
}
.header-profile-menu-item:hover svg path {
  fill: var(--primary-font-color);
}

.header-profile-themToggle-adj {
  margin: -6px;
}

/*Simple css to style it like a toggle switch*/
.theme-switch-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  width: 36px;
}
.theme-switch {
  display: inline-block;
  height: 16px;
  position: relative;
  width: 28px;
}

.theme-switch input {
  display: none;
}

.slider {
  background: var(--disabled-font-color);
  /* border: 1px solid var(--line-color); */

  height: 100%;
  width: 100%;
  box-sizing: border-box;
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.slider:before {
  background-color: #fff;
  content: "";
  height: 12px;
  width: 12px;

  box-sizing: border-box;
  left: 2px;
  top: 2px;
  position: absolute;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: var(--selected-font-color);
}

input:checked + .slider:before {
  transform: translateX(12px);
}

.slider.round {
  border-radius: 10px;
}

.slider.round:before {
  border-radius: 50%;
}

.header-prop-cont {
  position: relative;
  height: 100%;
  z-index: 50;
  margin-left: -6px;
}
.header-prop-wrap {
  position: absolute;
  border-radius: 10px;
  top: 0;
  overflow: hidden;
}
.header-prop-wrap-active {
  background: var(--foreground-color);
  box-shadow: var(--modal-shadow);
}

.header-prop-wrap-active .header-prop-act,
.header-prop-opt {
  border-bottom: 1px solid var(--line-color);
}
.header-prop-opt {
  padding: 12px 0 8px;
}

.header-prop-foot {
  padding: 8px 0 12px;
}

.header-prop-foot-butt {
  width: 100%;
  height: 32px;
  padding: 0 6px;
  box-sizing: border-box;
  cursor: pointer;
}

.header-prop-foot-butt:hover {
  background: var(--hover-color);
  color: var(--primary-font-color);
}

.header-prop-tile {
  width: 100%;
  padding: 6px;
  cursor: pointer;
  white-space: nowrap;
  box-sizing: border-box;
}
.header-prop-tile:hover {
  background: var(--hover-color);
}

.header-prop-tile svg {
  height: 24px;
  width: 24px;
}
.header-prop-tile-txt {
  pointer-events: none;
  margin-left: 16px;
}
.header-prop-tile-txt svg path {
  fill: var(--disabled-font-color);
}
.header-prop-tile-txt:hover svg path {
  fill: var(--primary-font-color);
}

.header-prop-tile img {
  height: 36px;
  width: 54px;
  border-radius: 5px;
  box-sizing: border-box;
  object-fit: cover;
  display: block;
}

.header-prop-tile-open svg {
  transform: rotate(180deg);
}
.header-prop-tile-open.header-prop-tile-active img {
  border: 2px solid var(--selected-font-color);
}
.header-prop-tile-open.header-prop-tile-active .primary-txt,
.header-prop-tile-open.header-prop-tile-active .secondary-txt {
  color: var(--selected-font-color);
}

.navigation-wraper {
  position: absolute;
}
.navigation-mobile-backdrop {
  display: none;
}
.navigation-mobile-backdrop-active {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  background: rgba(0, 0, 0, 0.5);
}
.navigation-container {
  position: fixed;
  top: 0;
  left: -256px;
  height: 100%;
  z-index: 100;
  border-radius: 0;
}

.navigation-container-active {
  left: 0;
  box-shadow: var(--modal-shadow);
}

.navigation-container-min,
.navigation-container-max {
  width: 256px;
}

@media only screen and (min-width: 768px) {
  .navigation-container {
    padding-top: 8px;
  }
}

@media only screen and (min-width: 1048px) {
  .navigation-wraper {
    position: relative;
  }
  .navigation-mobile-backdrop-active {
    display: none;
  }

  .navigation-container,
  .navigation-container-active {
    padding: 0;
    top: 16px;
    left: 16px;
    height: Calc(100% - 32px);
    box-shadow: none;
    border-radius: 10px;
  }

  .navigation-container-min {
    width: 68px;
  }
}

.nav-logo-wrap {
  padding: 12px 24px;
}

.nav-logo-wrap svg {
  height: 24px;
  display: block;
}
.nav-logo-wrap svg:first-child {
  fill: var(--primary-font-color);
}

.nav-dash-wrap {
  padding: 6px 18px 6px 8px;
  box-sizing: border-box;
}
.nav-dash-wrap-min {
  padding: 6px 0 8px;
}

.nav-dash-wrap svg {
  width: 24px;
  height: 24px;
}

.nav-dash-wrap svg path {
  -webkit-filter: #fff;
          filter: #fff;
}

.nac-dash-lin {
  width: 0;
  height: 32px;
  margin: 0 6px;
}

.nav-dash-wrap a {
  text-decoration: none;
}
.nav-dash-wrap .nav-section-butt-active button {
  opacity: 1;
  color: var(--selected-font-color);
}

.nav-section-min-icon-wrap {
  width: 100%;
}
.nav-section-min-icon-wrap:hover {
  background: var(--hover-color);
}
.nav-section-min-icon-wrap:hover button svg path {
  fill: var(--primary-font-color);
}
.nav-section-butt-active:hover button svg path {
  fill: var(--selected-font-color);
}

.nav-accnt-wrap {
  padding: 20px 24px;
  box-sizing: border-box;
  cursor: pointer;
}
.nav-accnt-wrap:hover {
  background: var(--hover-color);
}

.nav-accnt-wrap-min {
  margin: -4px 0 -4px -4px;
  display: flex;
  justify-content: center;
  transform: rotate(180deg);
}

.nav-accnt-wrap-min div {
  white-space: nowrap;
}

.nav-collap-wrap {
  padding: 6px 24px;
  box-sizing: border-box;
  border-top: 1px solid var(--line-color);
}

.nav-collap-wrap-min {
  padding: 6px 0;
}

.navigation-section-wrapper {
  height: 100%;
  overflow-y: auto;
  margin: -1px 0;
  border-top: 1px solid rgba(0, 0, 0, 0);
  border-bottom: 1px solid rgba(0, 0, 0, 0);
}

.navigation-section-wrapper-lines {
  border-top: 1px solid var(--line-color);
  border-bottom: 1px solid var(--line-color);
}
.navigation-section-wrapper-min::-webkit-scrollbar {
  display: none;
}
.navigation-section-wrapper::-webkit-scrollbar {
  width: 4px;
  position: absolute;
}

.navigation-section-wrapper::-webkit-scrollbar-thumb {
  background: var(--disabled-font-color);
  border-radius: 2px;
}

.navigation-section-wrapper::-webkit-scrollbar-thumb:hover {
  background: var(--secondary-font-color);
}

.nav-section {
  box-sizing: border-box;
  cursor: pointer;
}
.nav-section:hover {
  background: var(--hover-color);
}

.nav-section-min {
  border-top: 1px solid var(--line-color);
  padding: 8px 0;
}

.nav-section-title {
  padding: 20px 24px 0;
  position: relative;
}

.nav-section-title svg {
  display: none;
  width: 24px;
  height: 24px;
  position: absolute;
  right: 24px;
}

.nav-sec-sub-txt {
  padding: 0 24px 20px;
}

.nav-section-butt {
  text-decoration: none;
}

.nav-section-butt svg {
  width: 20px;
  height: 20px;
  margin-right: 16px;
}
.nav-section-butt > *:last-child {
  margin-right: 0;
}

.nav-section-butt svg path {
  fill: var(--secondary-font-color);
}
.nav-section-butt:hover svg path {
  fill: var(--primary-font-color);
}

.nav-section-act {
  background: var(--background-color);
  border: var(--border);
  border-radius: 10px;
  overflow: hidden;
  margin: 8px;
}

.nav-section-act:hover {
  background: var(--background-color);
}

.nav-section-act .nav-section-title {
  padding: 20px 16px 16px;
  cursor: pointer;
}
.nav-section-act .nav-section-title:hover {
  background: var(--hover-color);
}
.nav-section-act .nav-section-title:hover svg {
  display: block;
}

.nav-section-act .nav-section-butt {
  height: 32px;
  padding: 0 16px;
  cursor: pointer;
  color: var(--secondary-font-color);
}
.nav-section-act .nav-section-butt:hover {
  background: var(--hover-color);
  color: var(--primary-font-color);
}

.nav-section-act .nav-section-butt:last-child {
  margin-bottom: 20px;
}

.nav-section-act .nav-section-butt-active,
.nav-section-act .nav-section-butt-active:hover {
  color: var(--selected-font-color);
}

.nav-section-act .nav-section-butt-active svg path,
.nav-section-butt-active button svg path {
  fill: var(--selected-font-color);
}

.spinner {
  -webkit-animation: rotate 2s linear infinite;
          animation: rotate 2s linear infinite;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -25px 0 0 -25px;
}
.spinner .path {
  stroke: var(--disabled-font-color);
  stroke-linecap: round;
  -webkit-animation: dash 1.5s ease-in-out infinite, colors 6s infinite;
          animation: dash 1.5s ease-in-out infinite, colors 6s infinite;
}

@-webkit-keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}

.tooltip-wrap {
  position: relative;
}

.tooltip-window {
  display: none;
  position: absolute;
  left: 76px;
  top: 2px;

  background: rgba(22, 29, 36, 0.9);
  border-radius: 5px;
  color: white;
  padding: 7px 9px;
  white-space: nowrap;
}

.tooltip-wrap:hover .tooltip-window {
  display: block;
}

.app-feature-wrap {
  height: 100%;
  width: 100vw;
  margin: 0 -8px;
  box-sizing: border-box;
  overflow: hidden;
  padding: 0 8px;
}

@media only screen and (min-width: 768px) {
  .app-feature-wrap {
    margin: 0;
    width: 100%;
    padding: 0;
  }
}

.tabs-wrap {
  height: 44px;
  width: 100%;
  border-bottom: 1px solid var(--line-color);
}

.tabs-tab-wrap {
  height: 100%;
  text-decoration: none;
  color: var(--primary-font-color);
  position: relative;
}
.tabs-tab-wrap-selected {
  color: var(--selected-font-color);
}

.tabs-tab-wrap div:first-child {
  height: 24px;
  padding: 4px 16px 8px;
  border-radius: 5px 5px 0 0;
  cursor: pointer;
}

.tabs-tab-wrap div:first-child:hover {
  background: var(--hover-color);
}
.tabs-tab-select-lin {
  position: absolute;
  bottom: 0;
  width: 100%;
  display: none;
}

.tabs-tab-wrap-selected .tabs-tab-select-lin {
  display: block;
  height: 4px;
  border-radius: 4px 4px 0 0;
  background: var(--selected-font-color);
}

/* .app-home-wrap {
  height: 100%;
  box-sizing: border-box;
}

.app-grid {
  height: 100%;
  width: 100%;
  display: grid;
  max-width: 1256px;
  margin: auto;
  padding-top: 8px;
}

.app-grid-primary,
.app-grid-focusedm,
.app-grid-secondary {
  grid-template-rows: 100%;
  overflow: hidden;
}

.app-grid-primary {
  grid-template-columns: 1fr 0;
  grid-column-gap: 0;
}
.app-grid-focusedm,
.app-grid-secondary {
  grid-template-columns: 0 1fr;
  grid-column-gap: 0;
}

@media only screen and (min-width: 768px) {
  .app-grid {
    padding-top: 16px;
  }
  .app-grid-primary {
    grid-template-columns: 1fr 0;
    grid-column-gap: 0;
  }
  .app-grid-focused {
    grid-template-columns: 1fr 256px;
    grid-column-gap: 16px;
  }
  .app-grid-secondary {
    grid-template-columns: 0 1fr;
    grid-column-gap: 0;
  }
}

@media only screen and (min-width: 960px) {
  .app-grid {
    padding-top: 24px;
  }
} */

.home-dash-wrap {
  width: 100%;
  max-width: 936px;
}

.Dash-sect-head {
  margin: 32px 0 8px;
}
.dash-div-lin {
  width: 100%;
  margin-top: 36px;
  object-fit: fill;
  border-bottom: 1px solid var(--line-color);
}

.dash-Tile-wrap {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 2fr));

  grid-gap: 16px;
}

.dash-Tile-wrap > * {
  min-height: 200px;
}

@media only screen and (min-width: 632px) {
  .dash-Tile-lg {
    grid-column: 1 / 3;
  }
}

.dash-property-gallery-wrap {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 3fr));
  grid-gap: 16px;
}
.dash-property-gallery-wrap > * {
  height: 200px;
  cursor: pointer;
}
.dash-property-gallery-wrap > *:hover {
  background: var(--hover-color);
}

.dash-prop-tile-add {
  color: var(--selected-font-color);
  text-decoration: none;
}

.dash-prop-tile-add svg {
  height: 36px;
  width: 36px;
  margin-bottom: 12px;
}
.dash-prop-tile-add svg path {
  fill: var(--selected-font-color);
}

/* .dash-Tile-wrap {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 2fr));

  grid-gap: 16px;
}

.dash-Tile-wrap > * {
  min-height: 200px;
}

@media only screen and (min-width: 632px) {
  .dash-Tile-lg {
    grid-column: 1 / 3;
  }
} */


