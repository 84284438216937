@import url("https://rsms.me/inter/inter.css");
html {
  font-family: "Inter", sans-serif;
}
@supports (font-variation-settings: normal) {
  html {
    font-family: "Inter var", sans-serif;
  }
}
h1 {
}
h2 {
  font-size: 1.75;
  /* font-size: 2.25rem; */
  font-weight: 500;
  line-height: 1.3;
  margin: 0;
  /* margin: 0 0 1em; */
}
h3 {
  font-size: 1.3125rem;
  font-weight: 500;
  line-height: 1.3;
  margin: 0;
  /* margin: 0 0 1em; */
}
h4 {
  font-size: 1.09375rem;
  font-weight: 500;
  line-height: 1.3;
  margin: 0;
  /* margin: 0 0 1em; */
}
h5 {
  font-size: 1.75;
  font-weight: 500;
  line-height: 1.3;
  margin: 0;
}
p {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.71429;
  margin: 0 0 1em;
}
